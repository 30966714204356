// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {


  
  
  
  /** primary **/
  --ion-color-primary: #FF8200;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #fb9527;
  --ion-color-primary-tint: #ffae57;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #106231;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-toolbar-background: #ffffff;
  --yellowcolor : #fab700;
  --lightyellow: rgba(111, 111, 111, 0.057);
  --primarygreencolor: #0f4539;


}

* {

  font-family: "Hind Siliguri", sans-serif !important;
  font-weight: 500;
  font-style: normal;

   
}

.ios body {

  

  //    #carticon, #itemcount {
  //   color: #2bd156;
  //  }

  // ion-item {

  //   padding-top: 7px;
  //   padding-bottom: 7px;

  // }


   .mt5 {
    margin-top: 15px;
   }

  .iosmt10 {
    margin-top: 0%;
  }


  .mtg5 {
    margin-top: 15px;
  }

  .mtg10 {
    margin-top: 10px;

  }

  ion-radio::part(container) {
    width: 20px;
    height: 20px;

    border-radius: 8px;
    border: 1px solid #ddd;
  }

  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;

  }

  ion-radio.radio-checked::part(container) {
    // background: #6815ec;
    border-color: transparent;
  }

  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;

    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;

    transform: rotate(45deg);
  }



  ion-toolbar, ion-footer{

    // background: none !important;
    // background: transparent !important;

    --background: var(--ion-toolbar-background) !important
  }




  // .mt5 {

  //   margin-top: 20%;
  //  }



  .checkoutpage {

    // --background: red !important;
    .checkoutdetails {
        margin-top: 20px;

        ion-list {
        padding-top: 0px !important;
          padding-bottom: 7px !important;
          // background-color: orange !important;
        }

         .eachmenu {
              padding-top: 0px !important;
          padding-bottom: 0px !important;
         }

         .mtg5 {
          margin-top: 7px !important;
         }


      // width: 50px !important;
      // background-color: red !important;
    }
  }


  img[loading="lazy"] {
    clip-path: inset(0.6px) !important
  }


  .btnclass {
    margin-top: 12px;
  }
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body.dark {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66,140,255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

  .ios body.dark {
    // --ion-background-color: #000000;
    // --ion-background-color-rgb: 0,0,0;

    // --ion-text-color: #ffffff;
    // --ion-text-color-rgb: 255,255,255;

    // --ion-color-step-50: #0d0d0d;
    // --ion-color-step-100: #1a1a1a;
    // --ion-color-step-150: #262626;
    // --ion-color-step-200: #333333;
    // --ion-color-step-250: #404040;
    // --ion-color-step-300: #4d4d4d;
    // --ion-color-step-350: #595959;
    // --ion-color-step-400: #666666;
    // --ion-color-step-450: #737373;
    // --ion-color-step-500: #808080;
    // --ion-color-step-550: #8c8c8c;
    // --ion-color-step-600: #999999;
    // --ion-color-step-650: #a6a6a6;
    // --ion-color-step-700: #b3b3b3;
    // --ion-color-step-750: #bfbfbf;
    // --ion-color-step-800: #cccccc;
    // --ion-color-step-850: #d9d9d9;
    // --ion-color-step-900: #e6e6e6;
    // --ion-color-step-950: #f2f2f2;

    // --ion-item-background: #000000;

    // --ion-card-background: #1c1c1d;

    --lightyellow: rgba(131, 139, 143, 0.126);
  }

  // .ios  body.dark ion-modal {
  //   --ion-background-color: var(--ion-color-step-100);
  //   --ion-toolbar-background: var(--ion-color-step-150);
  //   --ion-toolbar-border-color: var(--ion-color-step-250);
  // }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body.dark {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }






    //fallback where it is not accepted
  /*
   * Dark Colors
   * -------------------------------------------
   */

   body.dark {
    --ion-color-primary: #FF8200;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #f49835;
    --ion-color-primary-tint: #fdad58;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #1db058;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-toolbar-background: #000000;
    --yellowcolor : #fab700;
    --lightyellow: rgba(162, 179, 187, 0.04);

    .userhome {

      ion-item {
        // height: 200px !important;
        background-color: rgba(70, 68, 68, 0.332);
        border-radius: 10px;
      }

    }


  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;

    --ion-toolbar-background: #000000;

    --yellowcolor : #fab700;



    //  img[loading="lazy"] {
    //    clip-path: inset(0.6px) !important
    //  }

  }

  .ios  body.dark ion-modal {
    // --ion-background-color: var(--ion-color-step-100);
    // --ion-toolbar-background: #121212;
    // --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #121212;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
    --yellowcolor : #fab700;
  }


  .md body {


    .mtg5 {
      margin-top: 5px;

    }

    .mtg10 {
      margin-top: 10px;

    }
    ion-toolbar, ion-footer{


      background: var(--ion-toolbar-background) !important;

      // --background: transparent !important;
    }



    .checkoutpage {

      // --background: red !important;
      .checkoutdetails {

          margin-top: 10px;
          ion-list {
          padding-top: 0px !important;
            padding-bottom: 7px !important;
            // background-color: orange !important;
          }

           .eachmenu {
                padding-top: 0px !important;
            padding-bottom: 0px !important;
           }

           .mtg5 {
            margin-top: 7px !important;
           }


        // width: 50px !important;
        // background-color: red !important;


      }


    }

    .paymentoption {  // checkout
      .inputbox {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
    }


    .userprofile {

      ion-list {
        padding-top: 3px !important;
        padding-bottom: 0px !important;
      }
    }


  /* Material Design styles */
// ion-segment-button::part(native) {
//   color: #000;
//   padding-top: 0px !important;
//   padding-bottom: 0px !important;
// }

  }


  body {

    ion-item {

    --background: transparent !important;
    }


  }

  .iconsize {
    font-size: 25px;
  }

  ion-button {
    font-weight: 400;
    text-transform: capitalize;
    --box-shadow: none;


  }

  ion-list {
    --ion-item-background: transparent;
    --box-shadow: none;

    --border-style: none

  }


  ion-item {

    --box-shadow: none !important;
    border-bottom: none !important;

  }



  .checkcart{

    padding: 10px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }


  ion-segment-button {
    // background-color: red;

    --background-checked: var(--ion-color-dark);
  --color-checked: var(--ion-color-success-contrast);
  --indicator-color: transparent;
   border-radius: 20px;
   height: 40px;
   min-height: 40px;

   ion-label {
    margin-top: 0px !important;
    margin-bottom: 0px !important;

   }

  }



  // ion-toolbar {

  //   --background: transparent !important;
  //   --padding-bottom: 0px !important;
  //   --border-style: none !important;
  // }





  .topicon {
    font-size: 80px;
  }


  ion-modal.modalcenter::part(content) {
    --height: 450px !important;
    // max-height: 50px !important;
    position: absolute;
    // bottom: 50%;

    // top:50%;
    // left:50%;
    transform:translate(-50%, -50%);
    // width:50%; /* You can specify ANY width values here */
    // --width: 90%;
     border-radius: 30px !important;
    // display: block;
  }

  ion-modal.autoheight::part(content) {
    // --height: auto !important;
    max-height: 250px !important;

    position: absolute;
     bottom: 0px !important;

    // top:50%;
    // left:50%;
    transform:translate(-50%, -50%);
    // width:50%; /* You can specify ANY width values here */
    // --width: 90%;
     border-radius: 30px;

    // display: block;
  }



  ion-modal.modal300px::part(content) {
    --height: 300px !important;
    // max-height: 50px !important;
    position: absolute;
    bottom: 0px;
    // --width: 90%;
     border-radius: 30px;
    display: block;
  }

  ion-modal.maxheight300px::part(content) {
    --height: 40% !important;
    // max-height: 50px !important;
    position: absolute;
    top: 30%;
    // --width: 90%;
     border-radius: 5px !important;
    display: block;
   
    ul {
      list-style-type: none !important;
      margin: 0;
      padding: 0;
    }
  }

  // ion-modal.maxheight300px{
   
  //   ul {
  //     // list-style-type: none !important;
  //     list-style: none;
    
  //   }
  // }

  ion-modal.modal400px::part(content) {
    --height: 400px !important;
    // max-height: 50px !important;
    position: absolute;
    bottom: 0px;
    // --width: 90%;
     border-radius: 30px;
    display: block;
  }


  ion-modal.modal100percent::part(content) {
    --height: 100% !important;
    // max-height: 50px !important;
    position: absolute;
    bottom: 0px;
    // --width: 90%;
     border-radius: 5px !important;
    display: block;
  }

  // :host(.toggle-checked) .toggle-icon {
  //   background: var(--background-checked);
  // }

//   .toggle-icon {
//     background: var(--background-checked) !important;
// }

// --background-checked: rgba(var(--ion-color-primary-rgb, 56, 128, 255), 0.5);

ion-toggle {

  --background-checked: #414141;

}

  .colormedium {
    color: var(--ion-color-medium);
  }

  .colorprimary {
    color: var(--ion-color-primary);
  }






  .checkoutpage {





//     .selectpaymentoption::part(backdrop) {


//         width: 80%;
//         height: 50%;




//  }


}

   .mt5 {

    margin-top: 10px;
   }


   .mt10 {

    margin-top: 10%;
   }


   .mt20 {

    margin-top: 20%;
   }

   .mt30 {

    margin-top: 30%;
   }

   .mt40 {

    margin-top: 40%;
   }

   .mt50 {

    margin-top: 50%;
   }



   ///@at-root
   ///
   ///
   ///

   .mb5 {

    margin-bottom: 5%;
   }


   .mb10 {

    margin-bottom: 10%;
   }


   .mb20 {

    margin-bottom: 20%;
   }

   .mb30 {

    margin-bottom: 30%;
   }

   .mb40 {

    margin-bottom: 40%;
   }

   .mb50 {

    margin-bottom: 50%;
   }




   .ngx-pagination {

    .pagination-next {

        a {
          border-radius: 5px !important;
            color: var(--ion-color-medium) !important;
        }
    }

    .pagination-previous {

      a {
        border-radius: 5px !important;
          color: var(--ion-color-medium) !important;
      }
  }
   }

   .statusboxsmall {


    div.online-indicator {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 10px;

        background-color: #0fcc45;
        border-radius: 50%;

        position: relative;
      }
      span.blink {
        display: block;
        width: 15px;
        height: 15px;

        background-color: #0fcc45;
        opacity: 0.7;
        border-radius: 50%;

        animation: blink 1s linear infinite;
      }

      /*Animations*/

    @keyframes blink {
        100% { transform: scale(2, 2);
                opacity: 0;
            }
    }



    .gooffline {

        .blink {
            background-color: transparent !important;
            animation: none !important;
        }



    }

    .gooffline.online-indicator {

        background-color: var(--ion-color-danger-tint);
        animation: none !important;
   }









}

.inputbox {
  // background-color: var(--ion-color-dark-contrast) !important;
  background-color: var(--ion-color-light);
  // border-radius: 30px;
  border-radius: 10px;
 }


 .inputbox-main {
  // background-color: var(--ion-color-dark-contrast) !important;
  --background: var(--lightyellow) !important;
  // border-radius: 30px;
  // --border-radius: 10px !important;
  // padding-left: 20px;
  --border-radius: 10px;
  
  input {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  select {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }
  // padding-bottom: 30px;
  // border: 1px solid var(--yellowcolor) !important;
 }



 .eachmenu {

  border-radius: 10px;
  // background-color: var(--ion-color-light-tint);
  background-color: var( --ion-color-light) !important;
  padding-top: 10px;
  padding-bottom: 10px;
  // margin-top: 20px;
}

// video {

//   //  height: 100%;
//   // padding-top: 300px;

// }

.reader {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.btnclass {

  margin-top: -5px;
  --border-radius: 10px;
  height: 45px;

}


  // ion-item::part(native) {
  //   background-color: var(--ion-color-danger-tint) !important;
  //   }

  // --ion-color-dark: #222428;
  // --ion-color-dark-rgb: 34, 36, 40;
  // --ion-color-dark-contrast: #ffffff;
  // --ion-color-dark-contrast-rgb: 255, 255, 255;
  // --ion-color-dark-shade: #1e2023;
  // --ion-color-dark-tint: #383a3e;




  /// new classes 
  .hide {
    display: none !important;
  }

  .selectbox {
    background-color: transparent;
    width: 100%;
    border: none !important;
    outline: none !important;

  }

  .cardboxclass {

    ion-item {
  
      background-color: var(--lightyellow);
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 15px;
  
    }
   
      
  }

  .cardbx {

    background-color: var(--lightyellow);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;

    ion-label {
        padding-bottom: 8px;;
    }
}

  .searchbox{
    --border-radius: 20px;
    // outline: none !important;
    --box-shadow: none;
    // border: 1px solid var(--lightyellow);
    --background: var(--lightyellow)
  }




//   ion-item::part(native) .item-inner{
//     margin-right: 0px;  
//     --background: red;
// }
